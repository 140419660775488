import * as React from "react"
import Layout from "../components/layout.js"
import Image from "../components/image"

const StrangeQueenWoodsPage = () => {
  return (
    <Layout title="The Strange and Melancholy Queen of the Woods">
        <h2>Field Notes</h2>
        <center><Image src="strangemelancholyqueenwoods-1.png" alt="Drawing" /></center>
    </Layout>
  )
}

export default StrangeQueenWoodsPage